import React, { useEffect, useState } from "react"
import ExposePage from "./expose"

const DetailExposePage = () => {
  const [ uuid, setUuid ] = useState(null);

  useEffect(
    () => {
      const action = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const uuid = urlParams.get("uuid");

        setUuid(uuid);
      }

      action();
    },
    []
  );

  return uuid ? <ExposePage uuid={uuid} /> : <div><h1>Lade ...</h1></div>

}

export default DetailExposePage;
